"use client";

import React, { useEffect, useState } from "react";
import { AiOutlineExport } from "react-icons/ai";
import { RiContactsBookUploadLine, RiDeleteBin6Line } from "react-icons/ri";
import { FaPlus, FaRegEdit } from "react-icons/fa";
import { GoSearch } from "react-icons/go";
import { VscCloudUpload } from "react-icons/vsc";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import api, { ApiResponse } from "../../../http/api/api_client";
import {
  DELETE_TRUCKS_ENDPOINT,
  EDIT_TRUCKS_ENDPOINT,
  GET_ALL_TRUCKS,
  SEARCH_TRUCKS,
} from "../../../helpers/constant";
import TableShimmer from "../../../components/shimmer/TableShimmer";
import Pagination from "../../../components/pagination/Pagination";
import Modal from "../../../components/modal/Modal";
import FormFile from "../../../components/formfile/FormFile";
import AddTruckModal from "../../../components/add-truck-modal";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import EditTruckModal from "../../../components/edit-truck-modal";
import { TruckiTypeNames, Trucks } from "../../../Types/Trucks";

const TrucksPage = () => {
  const [showAddTruckModal, setShowAddTruckModal] = useState<boolean>(false);
  const [showEditTruckModal, setShowEditTruckModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [itemOffset, setItemOffset] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [truckTableData, setTruckTableData] = useState<Trucks[]>([]);
  const [editingTruck, setEditingTruck] = useState<Trucks>();
  const [deletingTruckId, setDeletingTruckId] = useState<string | null>(null);
  const [showAddTruckOwner, setShowAddTruckOwner] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const router = useNavigate();

  const fetchAllTrucks = async () => {
    setIsLoading(true);
    try {
      const response: ApiResponse<any> = await api.get(GET_ALL_TRUCKS);
      if (response.statusCode == 200) {
        const data = response.data;

        // Sort the trucks by `createdAt` in descending order (most recent first)
        const sortedData = data.data.sort(
          (a: Trucks, b: Trucks) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );

        setTruckTableData(sortedData);
        console.log(data.data, "displaying truck data");
      }
    } catch (error) {
      console.error("Error fetching truck data:", error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };
  useEffect(() => {

    if (searchQuery !== "") {
      handleSearchDrivers();
    }
    fetchAllTrucks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, searchQuery]);

  const [activeComponent, setActiveComponent] = useState<
    "all" | "en route" | "available route" | "out of service"
  >("all");

  const handleClick = (
    component: "all" | "en route" | "available route" | "out of service"
  ) => {
    setActiveComponent(component);
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    const newOffset = selected * itemsPerPage;
    setItemOffset(newOffset);
  };

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setItemOffset(0);
  };

  const pageCount = Math.ceil(truckTableData.length / itemsPerPage);

  const currentItems = truckTableData.slice(
    itemOffset,
    itemOffset + itemsPerPage
  );

  const handleSearchDrivers = async () => {
    try {
      const response: ApiResponse<any> = await api.get(
        `${SEARCH_TRUCKS}?searchWords=${searchQuery}`
      );
      if (response.statusCode === 200) {
        setSearchResults(response.data.data);
      }
    } catch (error) {
      console.error("Error searching drivers:", error);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleEditTruckClick = (truck: Trucks) => {
    setShowEditTruckModal(true);
    setEditingTruck(truck);
  };

  const handleDeleteTruck = (truckId: string) => {
    setDeletingTruckId(truckId);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    if (deletingTruckId) {
      const response: ApiResponse<any> = await api.post(
        `${DELETE_TRUCKS_ENDPOINT}?truckId=${deletingTruckId}`
      );
      if (response.statusCode === 200) {
        setShowDeleteModal(false);
        setDeletingTruckId("");
        setTruckTableData((truckData) =>
          truckData.filter((truck) => truck.id !== deletingTruckId)
        );
      } else {
        console.error("Failed to delete driver:", response.statusText);
      }
    }
  };

  // *********styling the select component**********
  const customSelectStyles = {
    control: (provided: any, state: { isFocused: any }) => ({
      ...provided,
      border: state.isFocused ? "none" : "1px solid #CCCCCD",
      boxShadow: state.isFocused ? "0 0 0 1px #CCCCCD" : "none",
      padding: "0.4rem 10px",
      borderRadious: "10",
    }),
  };
  return (
    <>
      <div>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-2 gap-y-3 flex-wrap">
            <h1>Trucks</h1>
            <div className="bg-white-100 ml-5 text-sm flex justify-between items-center rounded-md py-1.5 px-1.5">
              <input
                type="text"
                placeholder="Search Trucks by No."
                value={searchQuery}
                onChange={handleChange}
                className="outline-none"
              />
              <span
                className="text-gray-500 cursor-pointer"
                onClick={handleSearchDrivers}
              >
                <GoSearch className="w-[20px] h-[20px]" />
              </span>
            </div>
          </div>

          <span className="font-bold">
            <button
              className="text-white-100 bg-darkBlue-100 rounded-md flex items-center py-3 pr-4 text-xs hover:bg-gray-500"
              onClick={() => setShowAddTruckModal(true)}
            >
              <span>
                <FaPlus className="w-10" />
              </span>{" "}
              <span>Add Truck</span>
            </button>
          </span>
        </div>

        <div className="flex justify-between mb-10 py-2 border-b border-gray-300">
          <div className="flex text-xs gap-4 my-4 max-w-[100vw] overflow-x-auto">
            <span
              className={`cursor-pointer text-nowrap ${activeComponent === "all"
                ? "text-myred-100 border-b border-myred-100 pb-1"
                : "hover:text-myred-100 hover:border-b hover:border-myred-100"
                }`}
              onClick={() => handleClick("all")}
            >
              ALL
            </span>
            <span
              className={`cursor-pointer text-nowrap ${activeComponent === "en route"
                ? "text-myred-100 border-b border-myred-100 pb-1"
                : "hover:text-myred-100 hover:border-b hover:border-myred-100"
                }`}
              onClick={() => handleClick("en route")}
            >
              EN ROUTE
            </span>
            <span
              className={`cursor-pointer text-nowrap ${activeComponent === "available route"
                ? "text-myred-100 border-b border-myred-100 pb-1"
                : "hover:text-myred-100 hover:border-b hover:border-myred-100"
                }`}
              onClick={() => handleClick("available route")}
            >
              AVAILABLE ROUTE
            </span>
            <span
              className={`cursor-pointer text-nowrap ${activeComponent === "out of service"
                ? "text-myred-100 border-b border-myred-100 pb-1"
                : "hover:text-myred-100 hover:border-b hover:border-myred-100"
                }`}
              onClick={() => handleClick("out of service")}
            >
              OUT OF SERVICE
            </span>
          </div>
        </div>

        {activeComponent === "all" && (
          <>
            <div className="">
              <div className="flex justify-end gap-2 mb-4">
                <button className="flex items-center gap-1 justify-between text-darkBlue-100 border border-darkBlue-100 bg-white-100 rounded-md p-2 text-xs">
                  <span>
                    <AiOutlineExport />
                  </span>
                  <span>Export Bluck</span>
                </button>
                <button className="flex items-center gap-1 justify-between border border-myred-100 bg-myred-300 text-myred-100 rounded-md p-2 text-xs">
                  <span>
                    <RiContactsBookUploadLine />
                  </span>
                  <span>Bluck Upload</span>
                </button>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="w-[100%] text-[10px] md:text-[12px] bg-white-100 text-left min-w-full divide-y">
                <thead className="bg-white-100 py-4 font-light">
                  <tr>
                    <th className="py-4 px-2 text-gray-350 tracking-wider">
                      Truck No
                    </th>
                    <th className="py-4 px-2 text-gray-350 tracking-wider">
                      Truck Type
                    </th>
                    <th className="py-4 px-2 text-gray-350 tracking-wider">
                      Capacity
                    </th>
                    <th className="py-4 px-2 text-gray-350 tracking-wider">
                      Truck Owner
                    </th>
                    <th className="py-4 px-2 text-gray-350 tracking-wider text-right">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="min-w-full divide-y">
                  {isLoading ? (
                    <TableShimmer />
                  ) : (
                    (searchResults.length > 0
                      ? searchResults
                      : currentItems
                    ).map((data) => (
                      <tr
                        key={data.id}
                        className="border-b border-gray-200 hover:bg-gray-200 cursor-pointer"
                      >
                        <td
                          className="py-4 px-2 whitespace-nowrap"
                          onClick={() => router(`/dashboard/trucks/${data.id}`)}
                        >
                          {data.plateNumber}
                        </td>
                        <td
                          className="py-4 px-2 whitespace-nowrap"
                          onClick={() => router(`/dashboard/trucks/${data.id}`)}
                        >
                          {TruckiTypeNames[data.truckType]}{" "}
                          {/* Use reverse mapping */}
                        </td>

                        <td
                          className={
                            data.truckCapacity === "Available"
                              ? "text-mygreen-100"
                              : data.truckCapacity === "Out of Service"
                                ? "text-myred-100"
                                : "py-4 px-2 whitespace-nowrap"
                          }
                          onClick={() => router(`/dashboard/trucks/${data.id}`)}
                        >
                          {data.truckCapacity}
                        </td>

                        <td
                          className="py-4 px-2 whitespace-nowrap"
                          onClick={() => router(`/dashboard/trucks/${data.id}`)}
                        >
                          {data.truckOwnerName}
                        </td>
                        <td className="flex items-center py-4 px-2 gap-x-4 justify-end">
                          <span
                            className="text-darkBlue-100 cursor-pointer hover:bg-gray-300 p-3 rounded-md"
                            onClick={() => handleEditTruckClick(data)}
                          >
                            <FaRegEdit />
                          </span>
                          <span
                            className="text-red-500  cursor-pointer hover:bg-gray-300 p-3 rounded-md"
                            onClick={() => handleDeleteTruck(data.id)}
                          >
                            <RiDeleteBin6Line />
                          </span>
                        </td>
                      </tr>
                    ))
                  )}
                  {searchResults.length === 0 && searchQuery && (
                    <tr>
                      <td colSpan={4}>
                        <div className="flex justify-center items-center h-60">
                          <img
                            src="/nodata.png"
                            width={100}
                            height={100}
                            alt="No data"
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="py-8 mb-[-40px] flex justify-between items-center px-2">
              <div className="flex items-center gap-2 text-sm text-gray-450">
                <span>View</span>
                <span>
                  <select
                    onChange={handleItemsPerPageChange}
                    value={itemsPerPage}
                    className="p-1 bg-white-100 cursor-pointer"
                  >
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </span>
                <span>truck per page</span>
              </div>
              <Pagination
                pageCount={pageCount}
                handlePageClick={handlePageChange}
              />
            </div>
          </>
        )}
      </div>
      <AddTruckModal
        showAddTruckModal={showAddTruckModal}
        setShowAddTruckModal={setShowAddTruckModal}
        handleFetchTrucks={() => fetchAllTrucks()}
      />
      <EditTruckModal
        showEditTruckModal={showEditTruckModal}
        truck={editingTruck}
        setShowEditTruckModal={setShowEditTruckModal}
        handleFetchTrucks={() => fetchAllTrucks()}
      />
      <ConfirmationModal
        isVisible={showDeleteModal}
        onClosed={() => {
          setShowDeleteModal(false);
          setDeletingTruckId(null);
        }}
      >
        <div className="w-0 lg:w-[90%] text-sm">
          <p>
            Are you sure you want to{" "}
            <span className="text-red-500">Remove</span> this Truck?
          </p>
          <div className="flex justify-between items-center text-sm py-4 mt-6">
            <button onClick={() => setShowDeleteModal(false)}>Cancel</button>
            <button
              className="bg-red-500 text-white-100 py-1 px-6 rounded-sm"
              onClick={handleConfirmDelete}
            >
              Delete
            </button>
          </div>
        </div>
      </ConfirmationModal>
      <Modal
        isVisible={showAddTruckOwner}
        onClosed={() => setShowAddTruckOwner(false)}
      >
        <form>
          <h1>Add Truck Owner</h1>
          <div className="flex flex-col justify-between gap-5">
            <input
              type="text"
              name="companyName"
              placeholder="Company Name"
              className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
            />
            <input
              type="text"
              name="email"
              placeholder="Email Address"
              className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
            />
            <input
              type="text"
              name="mobile"
              placeholder="Phone Number"
              className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
            />
            <input
              type="text"
              name="address"
              placeholder="Address"
              className="py-3 pl-4 w-full border border-gray-400 focus:outline-none focus:border-gray-400 rounded-md bg-transparent"
            />
            <Select
              name=""
              styles={customSelectStyles}
              options={[
                { value: "Jeep", label: "Jeep" },
                { value: "Benz", label: "Benz" },
                { value: "Cargo", label: "Cargo" },
                { value: "Kia", label: "Kia" },
              ]}
              placeholder="Truck Type"
              className="w-full border border-gray-400 border-none focus:outline-none focus:border-gray-400 rounded-md bg-transparent appearance-none"
            />
            <div className="py-3 lg:py-5 text-center">
              <button
                className="text-ms py-3 bg-darkBlue-100 w-[100%] rounded-md cursour-pointer text-white-100"
                type="submit"
              >
                Add Truck Owner
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default TrucksPage;
