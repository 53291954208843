import React, { useEffect, useState } from "react";
import { FaCircleArrowLeft } from "react-icons/fa6";
import { GrDocumentPdf } from "react-icons/gr";
import {
  TruckiType,
  TruckiTypeNames,
  Trucks,
} from "../../../../Types/Trucks";
import { AllDriverDetails } from "../../../../Types/Drivers";
import api, { ApiResponse } from "../../../../http/api/api_client";
import {
  ASSIGN_DRIVER_TO_TRUCK,
  GET_ALL_TRUCK_DRIVERS,
  GET_ALL_TRUCKSBYID,
  UPDATE_TRUCK_APPROVAL_STATUS,
} from "../../../../helpers/constant";
import MainLayout from "../../../../components/main-layout/MainLayout";
import { Link, useParams } from "react-router-dom";
import DetailsShimmerCard from "../../../../components/shimmer/DetailsShimmerCard";
import Modal from "../../../../components/modal/Modal";
import SearchableSelect from "../../../../components/select_formfield";

const TruckDetails = () => {
  const { truckId } = useParams();

  const [truckDetails, setTruckDetails] = useState<Trucks>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [assignTruckModal, setAssignTruckModal] = useState<boolean>(false);
  const [driversData, setDriversData] = useState<AllDriverDetails[]>([]);
  const [selectedDriverId, setSelectedDriverId] = useState<string>("");

  useEffect(() => {
    const fetchTruckDetails = async () => {
      setIsLoading(true);
      try {
        const response: ApiResponse<any> = await api.get(
          `${GET_ALL_TRUCKSBYID}?truckId=${truckId}`
        );
        if (response.statusCode === 200) {
          const data = response.data;
          console.log(data.data, 'displaying the driver"s data');
          setTruckDetails(data.data);
        }
      } catch (error) {
        console.error("Error fetching truck details:", error);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    };
    fetchTruckDetails();
    fetchAllDrivers();
  }, [truckId]);

  const options = driversData.map((driver) => ({
    value: driver.id,
    label: driver.name + " - " + driver.phone, // or any other property you want to display
  }));

  const handleChange = (
    selectedOption: { value: string; label: string } | null
  ) => {
    setSelectedDriverId(selectedOption?.value!);
  };

  const fetchAllDrivers = async () => {
    const response: ApiResponse<any> = await api.get(GET_ALL_TRUCK_DRIVERS);
    if (response.statusCode === 200) {
      const data = response.data;
      setDriversData(data.data);
    }
  };

  const assignDriverToTruck = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response: ApiResponse<any> = await api.post(
        ASSIGN_DRIVER_TO_TRUCK,
        {
          truckId: truckId,
          driverId: selectedDriverId,
        }
      );
      if (response.statusCode === 200) {
        await fetchAllDrivers();
        setAssignTruckModal(false);
      }
    } catch (error) {
      console.error("Error assigning driver to truck:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTruckDetails = async () => {
    setIsLoading(true);
    try {
      const response: ApiResponse<any> = await api.get(
        `${GET_ALL_TRUCKSBYID}?truckId=${truckId}`
      );
      if (response.statusCode === 200) {
        const data = response.data;
        console.log(data.data, 'displaying the driver"s data');
        setTruckDetails(data.data);
      }
    } catch (error) {
      console.error("Error fetching truck details:", error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const handleApprove = async () => {
    const response: ApiResponse<any> = await api.post(
      `${UPDATE_TRUCK_APPROVAL_STATUS}?truckId=${truckId}?approvalStatus=1`
    );
    if (response.statusCode === 200) {
      await fetchTruckDetails();
    }
  };

  const handleNotApprove = async () => {
    const response: ApiResponse<any> = await api.post(
      `${UPDATE_TRUCK_APPROVAL_STATUS}?truckId=${truckId}?approvalStatus=2`
    );
    if (response.statusCode === 200) {
      await fetchTruckDetails();
    }
  };

  return (
    <>
      <>
        <Link
          to="/dashboard/trucks/"
          className="flex items-center gap-3 text-darkBlue-100"
        >
          <span>
            <FaCircleArrowLeft />
          </span>
          <span>Back</span>
        </Link>
        <div className="flex items center gap-3 pt-4 pb-1 border-b border-gray-200">
          <h1 className="text-gray-350 mb-1">Trucks</h1>/
          <span>Truck Details</span>
        </div>
        <div className="rounded-md bg-white-100 p-4 text-sm">
          {isLoading ? (
            <DetailsShimmerCard />
          ) : (
            <>
              <div className="flex justify-between">
                <div>
                  <p className="flex flex-col my-4">
                    <span className="text-gray-350 mb-1">Truck Owner</span>
                    <span>{truckDetails?.truckOwnerName}</span>
                  </p>
                  <p className="flex flex-col my-4">
                    <span className="text-gray-350 mb-1">Truck Capacity</span>
                    <span>{truckDetails?.truckCapacity}</span>
                  </p>
                  <p className="flex flex-col my-4">
                    <span className="text-gray-350 mb-1">
                      Road worthiness Exp date
                    </span>
                    <span>{`${truckDetails?.roadWorthinessExpiryDate}`}</span>
                  </p>
                </div>
                <div>
                  <p className="flex flex-col my-4">
                    <span className="text-gray-350 mb-1">Truck Type</span>
                    <span> {TruckiTypeNames[truckDetails!.truckType]}</span>
                  </p>
                  <p className="flex flex-col my-4">
                    <span className="text-gray-350 mb-1">Driver</span>
                    <span
                      className="font-black"
                      onClick={() => setAssignTruckModal(true)}
                    >
                      {selectedDriverId ? (
                        driversData.find(
                          (driver) => driver.id === selectedDriverId
                        )?.name || (
                          <button className="text-darkBlue-100 cursor-pointer">
                            Assign a Driver
                          </button>
                        )
                      ) : (
                        <button className="text-darkBlue-100 cursor-pointer">
                          Assign a Driver
                        </button>
                      )}
                    </span>
                  </p>
                  <p className="flex flex-col my-4">
                    <span className="text-gray-350 mb-1">
                      Insurance Exp. date
                    </span>
                    <span>{`${truckDetails?.insuranceExpiryDate}`}</span>
                  </p>
                </div>
                <div>
                  <p className="flex flex-col my-4">
                    <span className="text-gray-350 mb-1">Truck plate No.</span>
                    <span>{truckDetails?.plateNumber}</span>
                  </p>
                  <p className="flex flex-col my-4">
                    <span className="text-gray-350 mb-1">
                      Truck Lincense Exp. date
                    </span>
                    <span>{`${truckDetails?.truckLicenseExpiryDate}`}</span>
                  </p>

                  <div className="flex  flex-col space-y-3 font-bold ">
                    {truckDetails?.truckStatus === 2 && (
                      <button
                        className="text-sm text-white-100 bg-slate-800 rounded-md text-center items-center py-3 hover:bg-gray-500 px-4 lg:px-10"
                        onClick={() => handleApprove()}
                      >
                        <span>Approve</span>
                      </button>
                    )}

                    {truckDetails?.truckStatus === 1 && (
                      <button
                        className="text-sm text-white-100 bg-red-600 rounded-md text-center items-center py-3 hover:bg-gray-500 px-4 lg:px-10"
                        onClick={() => handleApprove()}
                      >
                        <span>Block</span>
                      </button>
                    )}
                  </div>

                  {truckDetails?.truckStatus === 0 && (
                    <div className="flex  flex-col space-y-3 font-bold">
                      <button
                        className="text-sm text-white-100 bg-green-700 rounded-md text-center items-center py-3 hover:bg-gray-500 px-4 lg:px-10"
                        onClick={() => handleApprove()}
                      >
                        <span>Approve</span>
                      </button>

                      <button
                        className="text-sm text-white-100 bg-red-600 rounded-md text-center items-center py-3 hover:bg-gray-500 px-4 lg:px-10"
                        onClick={() => handleNotApprove()}
                      >
                        <span>Reject</span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <p className="text-gray-350 mt-4">Critical Errors</p>
              <div className="p-4 bg-gray-100 rounded-md mt-3"></div>
              <p className="text-gray-350 mt-4">Documents</p>
              {truckDetails!.documents.length > 0 &&
                truckDetails?.documents.map((documentUrl) => (
                  <p
                    className="px-4 py-2 bg-gray-100 rounded-md mt-3"
                    key={documentUrl}
                  >
                    <a
                      href={documentUrl}
                      target="_blank"
                      className="flex justify-between gap-3"
                      rel="noopener noreferrer"
                    >
                      <div className="flex flex-row">
                        <span className="text-myred-100 mr-5">
                          <GrDocumentPdf />
                        </span>
                        <span>{documentUrl.split("/").pop()}</span>
                      </div>
                      <p>View File</p>
                    </a>
                  </p>
                ))}
            </>
          )}
        </div>
      </>
      <Modal
        isVisible={assignTruckModal}
        onClosed={() => setAssignTruckModal(false)}
      >
        <form onSubmit={assignDriverToTruck}>
          <h1 className="my-5">Assign a Driver</h1>
          <div className="my-10">
            <SearchableSelect options={options} onChange={handleChange} />
          </div>
          <button
            className={`py-3 bg-darkBlue-100 w-[100%] rounded-md my-10 cursour-pointer text-white-100 ${
              isLoading ? "opacity-50" : ""
            }`}
            type="submit"
          >
            {isLoading ? "Assigning..." : "Assign Truck"}
          </button>
        </form>
      </Modal>
    </>
  );
};

export default TruckDetails;
