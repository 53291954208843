import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";

interface AddressFormProps {
  onAddressSelect: (lat: number, lng: number, address: string) => void;
  AddressLabel: string;
  clearLocationAddress: boolean;
  defaultLat?: number;
  defaultLng?: number;
  defaultAddress?: string;
}

const mapContainerStyle = {
  width: "100%",
  height: "200px",
};

const defaultCenter = {
  lat: 37.7749, // Default latitude (San Francisco)
  lng: -122.4194, // Default longitude
};

interface AddressOption {
  label: string;
  value: {
    place_id: string;
  };
}

const AddressForm: React.FC<AddressFormProps> = ({
  onAddressSelect,
  AddressLabel,
  clearLocationAddress,
  defaultLat = defaultCenter.lat,
  defaultLng = defaultCenter.lng,
  defaultAddress = "",
}) => {
  const [address, setAddress] = useState<AddressOption | null>(
    defaultAddress
      ? {
        label: defaultAddress,
        value: { place_id: "" }, // Placeholder value for preloaded address
      }
      : null
  );
  const [markerPosition, setMarkerPosition] = useState<{
    lat: number;
    lng: number;
  } | null>(defaultLat && defaultLng ? { lat: defaultLat, lng: defaultLng } : null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBwSX4s9x4b_AWDm_Mu9GL60EBp4xQfecA", // Replace with your actual API Key
    libraries: ["places"],
  });

  useEffect(() => {
    if (clearLocationAddress) {
      clearLocation();
    }
  }, [clearLocationAddress]);

  const handleSelect = async (selectedAddress: AddressOption | null) => {
    setAddress(selectedAddress);
    if (selectedAddress) {
      try {
        const results = await geocodeByPlaceId(selectedAddress.value.place_id);
        const latLng = await getLatLng(results[0]);
        setMarkerPosition(latLng);

        onAddressSelect(latLng.lat, latLng.lng, selectedAddress.label);
      } catch (error) {
        console.error("Error fetching geocode information:", error);
      }
    }
  };

  const clearLocation = () => {
    setAddress(null);
    setMarkerPosition(null);
    onAddressSelect(0, 0, "");
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div>
      <label className="text-gray-400 text-sm">{AddressLabel}</label>
      <GooglePlacesAutocomplete
        apiKey={"AIzaSyD_vw1zEL0mIty7KaZcsrAy67XFHF5z--w"}
        selectProps={{
          value: address,
          onChange: handleSelect,
          placeholder: "Enter an address",
        }}
      />
      {/* {markerPosition && (
        <div className="mt-3">
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={14}
            center={markerPosition || defaultCenter}
          >
            <Marker position={markerPosition} />
          </GoogleMap>
        </div>
      )} */}
    </div>
  );
};

export default AddressForm;
